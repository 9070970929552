var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"bottom":"","left":"","max-width":"90%","min-width":"275","offset-x":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-card',_vm._g(_vm._b({staticClass:"py-2 px-4",staticStyle:{"position":"fixed","top":"164px","right":"-35px"},attrs:{"id":"settings","color":"#0000004D","dark":"","flat":"","width":"100"}},'v-card',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-cog ")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{staticClass:"py-2"},[_c('base-title',{attrs:{"align":"center","title":"Theme Colors","space":"0"}}),_c('v-card-text',[(_vm.$vuetify.theme.dark)?_c('v-item-group',{staticClass:"d-flex justify-center",model:{value:(_vm.$vuetify.theme.themes.dark.primary),callback:function ($$v) {_vm.$set(_vm.$vuetify.theme.themes.dark, "primary", $$v)},expression:"$vuetify.theme.themes.dark.primary"}},_vm._l((_vm.colors),function(color){return _c('v-item',{key:color,attrs:{"value":color},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('base-avatar',{staticClass:"ma-2",staticStyle:{"cursor":"pointer"},attrs:{"color":color,"outlined":active,"size":"48"},on:{"click":function($event){$event.stopPropagation();return toggle($event)}}})]}}],null,true)})}),1):_c('v-item-group',{staticClass:"d-flex justify-center",model:{value:(_vm.$vuetify.theme.themes.light.primary),callback:function ($$v) {_vm.$set(_vm.$vuetify.theme.themes.light, "primary", $$v)},expression:"$vuetify.theme.themes.light.primary"}},_vm._l((_vm.colors),function(color){return _c('v-item',{key:color,attrs:{"value":color},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('base-avatar',{staticClass:"ma-2",staticStyle:{"cursor":"pointer"},attrs:{"color":color,"outlined":active,"size":"48"},on:{"click":function($event){$event.stopPropagation();return toggle($event)}}})]}}],null,true)})}),1),_c('v-divider',{staticClass:"my-6"}),_c('div',{staticClass:"text-center"},[(!_vm.$vuetify.theme.dark)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"info","small":"","fab":""},on:{"click":_vm.darkMode}},on),[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-moon-waxing-crescent ")])],1)]}}],null,false,2841804204)},[_c('span',[_vm._v("Dark Mode On")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"info","small":"","fab":""},on:{"click":_vm.darkMode}},on),[_c('v-icon',{attrs:{"color":"yellow"}},[_vm._v(" mdi-white-balance-sunny ")])],1)]}}])},[_c('span',[_vm._v("Dark Mode Off")])])],1),_c('v-divider',{staticClass:"my-6"}),_c('base-btn',{staticClass:"mb-6",attrs:{"block":"","href":"https://store.vuetifyjs.com/product/zero-theme-free/?ref=vtyd-settings","color":"accent","target":"_blank"}},[_vm._v(" Free Download ")]),_c('base-btn',{attrs:{"to":{ name: 'Sink' },"block":"","color":"grey darken-1","dark":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" View All Components ")]),_c('v-divider',{staticClass:"my-6"}),_c('base-img',{staticClass:"mx-auto mb-6",attrs:{"src":"https://cdn.vuetifyjs.com/images/logos/vuetify-logo-light.png","width":"88"}}),_c('div',{staticClass:"text-center"},[_c('base-btn',{attrs:{"tile":false,"to":{ name: 'Pro' },"rounded":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Go Pro "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-rocket ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }